import Vue from "vue";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import Notice from "~/entities/Notice";
import config from "~/nuxt.config";
import { RootState } from "~/store/index";

export const state = () => ({
  alertsBag: {} as any,
  errorsBag: {} as any,
  errorMessages: {
    notMoreThan6Stages: "not more than 6 stages message" as string,
  },

  // new way
  activeNotices: [] as Array<String>,
  globalNotices: [] as Array<String>,
  alertResponses: {} as Array<Notice>,
});

export type ErrorsBagState = ReturnType<typeof state>;

export const getters: GetterTree<ErrorsBagState, RootState> = {
  // og
  errorsBag: (state) => state.errorsBag,
  alertsBag: (state) => state.alertsBag,
  isEmpty: (state) => !Object.keys(state.errorsBag).length,
  // rework
  activeNotices: (state) => state.activeNotices,
  globalNotices: (state) => state.globalNotices,
  alertResponses: (state) => state.alertResponses as Array<Notice>,
};

export const mutations: MutationTree<ErrorsBagState> = {
  // og
  addError: (state, alertKey: any) =>
    Vue.set(state.errorsBag, alertKey, alertKey),
  clearError: (state, alertKey: any) => {
    Vue.delete(state.errorsBag, alertKey);
  },
  clearAllErrors: (state) => {
    state.errorsBag = {};
  },
  addAlert: (state, alertKey: string) => {
    Vue.set(state.alertsBag, alertKey, alertKey);
    // state.alertsBag[alertKey] = alertKey;
  },
  clearAlert: (state, alertKey: any) => {
    Vue.delete(state.alertsBag, alertKey);
    // delete state.alertsBag[alertKey];
  },
  clearAllAlerts: (state) => {
    state.alertsBag = {};
  },
  // rework
  setActiveNotices(state, payload) {
    state.activeNotices = payload;
  },
  setGlobalNotices(state, payload) {
    state.globalNotices = payload;
  },
  removeActiveNotice(state, payload) {
    const index = state.activeNotices.indexOf(payload);
    if (index !== -1) {
      state.activeNotices.splice(index, 1);
    }
  },
  addActiveNotice(state, payload) {
    const index = state.activeNotices.indexOf(payload);
    if (index === -1) {
      state.activeNotices.push(payload);
    }
  },
  removeGlobalNotice(state, payload) {
    const index = state.globalNotices.indexOf(payload);
    if (index !== -1) {
      state.globalNotices.splice(index, 1);
    }
  },
  addGlobalNotice(state, payload) {
    const index = state.globalNotices.indexOf(payload);
    if (index === -1) {
      state.globalNotices.push(payload);
    }
  },
  setAlertResponses(state, payload) {
    state.alertResponses = payload;
  },
  saveAlertResponse(state, payload) {
    state.alertResponses[payload.key] = payload.value; // as notice
  },
};

export const actions: ActionTree<ErrorsBagState, RootState> = {
  // og
  clearIndividualError({ commit }, value) {
    commit("clearAlert", value);
  },
  // rework
  async requestAlert({ commit }, data) {
    const _this = this;
    try {
      let name, replacements;
      if (typeof data === "string" || data instanceof String) {
        name = data;
        replacements = false;
      } else if (data) {
        name = data.name;
        replacements = data.replacements;
      } else {
        return;
      }
      const alerts = _this.getters["errorsBag/alertResponses"];
      if (alerts && alerts[name]) {
        // ask here if the notice was already requested.
        const alertResponse = new Notice(alerts[name]);
        if (replacements) {
          alertResponse.replacements = replacements;
        }
        commit("saveAlertResponse", { key: name, value: alertResponse });
        commit("addActiveNotice", name);
      } else {
        // if it isn't, pass replacements and move along
        const getAlertResponse = await Promise.all([
          this.$axios.$get(
            (config &&
            config.publicRuntimeConfig &&
            config.publicRuntimeConfig.apiURL
              ? config.publicRuntimeConfig.apiURL
              : "") +
              "/api/Alerts/name/" +
              name
          ),
        ]);
        if (
          getAlertResponse.length &&
          getAlertResponse[0] &&
          getAlertResponse[0].value
        ) {
          const alertResponse = new Notice(getAlertResponse[0].value);
          if (replacements) {
            alertResponse.replacements = replacements;
          }
          commit("saveAlertResponse", { key: name, value: alertResponse });
          commit("addActiveNotice", name);
        } else {
          console.log("failed to get response"); // failed to get response
        }
      }
    } catch (e) {
      console.log(e); // something terrible happened
    }
  },
  async requestNotifications({ commit }, data) {
    const _this = this;
    const parseData = new Date(data).toUTCString();
    try {
      const utcTime: any = parseData;
      let replacements;

      const alerts = _this.getters["errorsBag/alertResponses"];
      if (alerts && alerts[utcTime]) {
        // ask here if the notice was already requested.
        const alertResponse = new Notice(alerts[utcTime]);
        if (replacements) {
          alertResponse.replacements = replacements;
        }
        commit("saveAlertResponse", {
          key: alertResponse.page,
          value: alertResponse,
        });
        commit("addGlobalNotice", alertResponse.page);
      } else {
        // if it isn't, pass replacements and move along
        const getAlertResponse = await Promise.all([
          this.$axios.$get(
            (config &&
            config.publicRuntimeConfig &&
            config.publicRuntimeConfig.apiURL
              ? config.publicRuntimeConfig.apiURL
              : "") +
              "/api/Alerts/time/" +
              utcTime
          ),
        ]);

        if (
          getAlertResponse.length &&
          getAlertResponse[0][0] &&
          getAlertResponse[0][0].message
        ) {
          getAlertResponse[0].forEach((response) => {
            const alertResponse = new Notice(response);
            if (replacements) {
              alertResponse.replacements = replacements;
            }

            commit("saveAlertResponse", {
              key: alertResponse.page,
              value: alertResponse,
            });
            commit("addGlobalNotice", alertResponse.page);
          });
        }
      }
    } catch (e) {
      console.log(e); // something terrible happened
    }
  },
  clearAlertResponses({ commit }) {
    commit("setAlertResponses", {});
  },
  clearActiveNotices({ commit }) {
    commit("setActiveNotices", []);
  },
  removeNotice({ commit }, name) {
    commit("removeActiveNotice", name);
  },
  addNotice({ commit }, name) {
    commit("addActiveNotice", name);
  },
};
