import { AVSStatus, AVSSubStatus } from "./AVS";

class Place implements google.maps.places.PlaceResult {
  adr_address?: string | undefined;
  geometry?: google.maps.places.PlaceGeometry | undefined;
  html_attributions?: string[] | undefined;
  icon?: string | undefined;
  icon_background_color?: string | undefined;
  international_phone_number?: string | undefined;
  name?: string | undefined;
  utc_offset_minutes?: number | undefined;
  formatted_address?: string | undefined;
  address_components?: google.maps.GeocoderAddressComponent[] | undefined;
  types!: string[];
  vicinity?: string | undefined;

  public originalFormattedAddress?: string;
  public company?: string;

  public constructor(init: Partial<Place>) {
    Object.assign(this, init);
    if (this.types?.length > 0) {
      const typesToCheck = ["bicycle_store", "store", "establishment"];
      if (typesToCheck.some((type) => this.types.includes(type))) {
        this.company = this.name;
      } else {
        this.company = "";
      }
    }
  }

  reverseResolvedAddressComponents(resolvedAddress: any): {
    detail: any;
    changes: boolean;
  } {
    if (!this.originalFormattedAddress) {
      this.originalFormattedAddress = this.formatted_address as string;
    }
    let changes = false;
    const detail = {} as any;
    const components = [] as any[];
    if (this.address_components) {
      for (let i = 0; i < this.address_components.length; i++) {
        const component = this.address_components[i];
        components.push(component.types[0]);
      }
      if (resolvedAddress?.withAddress1) {
        changes = true;
        detail.address1 = resolvedAddress.withAddress1;
        detail.streetAddress = resolvedAddress.withAddress1;
        this.formatted_address = resolvedAddress.withAddress1;
        if (components.includes("subpremise")) {
          // adding address 2 number if present, right after the street address
          const address2 = this.address_components.filter(
            (component) => component.types[0] === "subpremise"
          )[0].long_name as string | number;
          this.formatted_address +=
            address2 && !isNaN(address2 as number)
              ? " #" + (address2 as string)
              : " " + (address2 as string);
        }
        if (!components.includes("route")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["route"],
          });
        }
        if (!components.includes("neighborhood")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["neighborhood"],
          });
        }
        // add to place address components
      }
      if (resolvedAddress?.withAddress2) {
        changes = true;
        detail.address2 = resolvedAddress.withAddress2;
        this.formatted_address += ", " + resolvedAddress.withAddress2;
      }
      if (resolvedAddress && resolvedAddress.withAddress3) {
        changes = true;
        detail.address3 = resolvedAddress.withAddress3;
        this.formatted_address += ", " + resolvedAddress.withAddress3;
      }
      if (resolvedAddress?.withCity) {
        changes = true;
        detail.city = resolvedAddress.withCity;
        this.formatted_address += ", " + resolvedAddress.withCity;
        if (!components.includes("locality")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["locality"],
          });
        }
        // add to place address components
      }
      if (resolvedAddress?.withPostalCode) {
        changes = true;
        detail.postalCode = resolvedAddress.withPostalCode;
        if (!components.includes("postal_code")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["postal_code"],
          });
        }
        // add to place address components
      }
      if (
        typeof resolvedAddress?.withResidential === "boolean" &&
        resolvedAddress?.withResidential != null
      ) {
        changes = true;
        detail.isResidential = resolvedAddress?.withResidential;
      }
      if (
        typeof resolvedAddress?.withPassedAvs === "boolean" &&
        resolvedAddress?.withPassedAvs != null
      ) {
        changes = true;
        detail.passedAvs = resolvedAddress.withPassedAvs;
        if (resolvedAddress.withPassedAvs) {
          detail.status = AVSStatus.success;
          detail.subStatus = AVSSubStatus.onAddressReplace;
        }
      }
      if (resolvedAddress?.withState) {
        this.formatted_address +=
          ", " +
          resolvedAddress.withState +
          " " +
          resolvedAddress.withPostalCode;
        changes = true;
        detail.state = {
          id: resolvedAddress.withState,
          stateprovcode: resolvedAddress.withState,
          stateprov: resolvedAddress.withState,
          country: resolvedAddress.withCountry,
          label: resolvedAddress.withState,
        };
        if (!components.includes("administrative_area_level_1")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["administrative_area_level_1"],
          });
        }
      }
      if (resolvedAddress?.withCountry) {
        changes = true;
        detail.country = {
          code: resolvedAddress.withCountry,
          name: resolvedAddress.withCountry,
        };
        // TODO: check this part tomorrow to see how do working that part
        this.formatted_address += ", " + resolvedAddress.withCountry;
        if (!components.includes("country")) {
          this.address_components.push({
            long_name: "",
            short_name: "",
            types: ["country"],
          });
        }
      }
      if (
        this.address_components.constructor === Array &&
        this.address_components.length
      ) {
        let cityFilled = false;
        for (let i = 0; i < this.address_components.length; i++) {
          const component = this.address_components[i];
          const type = component.types[0];
          switch (type) {
            case "route":
              if (detail.address1) {
                this.address_components[i].long_name = detail.address1;
                this.address_components[i].short_name = detail.address1;
              }
              break;
            case "postal_code":
              if (detail.postalCode) {
                this.address_components[i].long_name = detail.postalCode;
                this.address_components[i].short_name = detail.postalCode;
              }
              break;
            case "neighborhood":
              if (detail.city) {
                this.address_components[i].long_name = detail.city;
                this.address_components[i].short_name = detail.city;
                cityFilled = true;
              }
              break;
            case "locality":
              if (detail.city && !cityFilled) {
                this.address_components[i].long_name = detail.city;
                this.address_components[i].short_name = detail.city;
              }
              break;
            case "country":
              if (detail.country) {
                // this.address_components[i].long_name = detail.country.name;
                this.address_components[i].short_name = detail.country.code;
              }
              break;
            case "administrative_area_level_1":
              if (detail.state) {
                // this.address_components[i].long_name = detail.state?.label;
                this.address_components[i].short_name =
                  typeof detail.state === "string"
                    ? detail.state
                    : detail.state.stateprovcode;
              }
              break;
          }
        }
      }
    }
    if (changes) {
      // here, should call complete
      this.formatLabel?.(components, resolvedAddress);
    }
    return { detail, changes };
  }

  reverseResolvedLocationComponents(resolvedLocation: any): boolean {
    // adds components to google place
    if (
      this.address_components?.constructor === Array &&
      this.address_components.length
    ) {
      const formattedAddress = this.formatted_address as string;
      if (!this.originalFormattedAddress) {
        this.originalFormattedAddress = formattedAddress;
      }
      let changes = false;
      const components = [] as any[];
      for (let i = 0; i < this.address_components.length; i++) {
        const component = this.address_components[i];
        components.push(component.types[0]);
      }

      if (resolvedLocation.postal_code && !components.includes("postal_code")) {
        // only true is postal code is missing
        changes = true;
        this.address_components.push({
          long_name: resolvedLocation.postal_code,
          short_name: resolvedLocation.postal_code,
          types: ["postal_code"],
        });
      }

      if (
        resolvedLocation.administrative_area_level_2 &&
        !components.includes("administrative_area_level_1")
      ) {
        // only true is state is missing
        changes = true;
        this.address_components.push({
          long_name: resolvedLocation.administrative_area_level_2,
          short_name: resolvedLocation.administrative_area_level_2,
          types: ["administrative_area_level_1"],
        });
      }
      if (resolvedLocation.locality && !components.includes("locality")) {
        // only true is city is missing
        changes = true;
        this.address_components.push({
          long_name: resolvedLocation.locality,
          short_name: resolvedLocation.locality,
          types: ["locality"],
        });
      }
      if (changes) {
        // here, should call complete
        this.formatLabel(components, resolvedLocation);
      }
      return changes;
    }
    return false;
  }

  nameExists(
    name: string,
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): boolean {
    if (name && addressComponents?.length) {
      return addressComponents.some(
        (component) =>
          component.long_name.toLowerCase().includes(name) ||
          component.short_name.toLowerCase().includes(name)
      );
    }
    return false;
  }

  public getAddressProperty(property: string): string {
    if (this.address_components) {
      for (let i = 0; i < this.address_components.length; i++) {
        const component = this.address_components[i];
        if (component.types[0] === property) {
          return component.long_name;
        }
      }
    }
    return "";
  }

  public formatLabel(components, resolved): void {
    try {
      if (components === false) {
        components = [] as any[];
        if (this.address_components) {
          for (let i = 0; i < this.address_components.length; i++) {
            const component = this.address_components[i];
            if (
              component.types[0] &&
              (component.long_name || component.short_name)
            ) {
              components[component.types[0]] = {
                long_name: component.long_name ? component.long_name : "",
                short_name: component.short_name ? component.short_name : "",
              };
            }
          }
        }
      }
      let formattedAddress: string = this!.formatted_address as string;
      const originalAddress = this?.originalFormattedAddress as string;

      if (resolved) {
        if (
          components &&
          !components.includes("locality") &&
          resolved &&
          !resolved.locality &&
          !resolved.withCity
        ) {
          formattedAddress =
            (resolved.postal_code
              ? resolved.postal_code
              : resolved.withPostalCode
              ? resolved.withPostalCode
              : "") +
            " " +
            (formattedAddress as string);
        } else if (resolved && !resolved.locality && !resolved.withCity) {
          formattedAddress =
            (resolved.postal_code
              ? resolved.postal_code
              : resolved.withPostalCode
              ? resolved.withPostalCode
              : "") +
            " " +
            (formattedAddress as string);
        } else if (formattedAddress.split(",").length - 1 > 1) {
          formattedAddress = (formattedAddress as string)?.replace(
            ", " +
              (resolved.administrative_area_level_2
                ? resolved.administrative_area_level_2
                : resolved.withState
                ? resolved.withState
                : "") +
              ",",
            ", " +
              (resolved.administrative_area_level_2
                ? resolved.administrative_area_level_2
                : resolved.withState
                ? resolved.withState
                : "") +
              " " +
              (resolved.postal_code
                ? resolved.postal_code
                : resolved.withPostalCode
                ? resolved.withPostalCode
                : "") +
              ","
          );
        } else if (formattedAddress.split(",").length - 1 > 0) {
          if (
            formattedAddress.indexOf(
              resolved.postal_code
                ? resolved.postal_code
                : resolved.withPostalCode
                ? resolved.withPostalCode
                : ""
            ) < 0
          ) {
            formattedAddress =
              (resolved.postal_code
                ? resolved.postal_code
                : resolved.withPostalCode
                ? resolved.withPostalCode
                : "") +
              " " +
              (formattedAddress as string);
          } else {
            formattedAddress =
              (resolved.administrative_area_level_2
                ? resolved.administrative_area_level_2 + " "
                : resolved.withState
                ? resolved.withState + " "
                : "") +
              (resolved.postal_code
                ? resolved.postal_code
                : resolved.withPostalCode
                ? resolved.withPostalCode
                : "") +
              (resolved.country
                ? ", " + resolved.country
                : resolved.withCountry
                ? ", " + resolved.withCountry
                : "");
          }
          if (formattedAddress.split(",").length - 1 < 0) {
            formattedAddress = this.formatted_address as string;
          }
        } else {
          formattedAddress =
            (resolved.locality
              ? resolved.locality
              : resolved.withCity
              ? resolved.withCity
              : "") +
            ", " +
            (resolved.postal_code
              ? resolved.postal_code
              : resolved.withPostalCode
              ? resolved.withPostalCode
              : "") +
            " " +
            (resolved.administrative_area_level_2
              ? resolved.administrative_area_level_2
              : resolved.withState
              ? resolved.withState
              : "") +
            (resolved.country
              ? ", " + resolved.country
              : resolved.withCountry
              ? ", " + resolved.withCountry
              : "");
        }
      }

      let name: string = this.name ? this.name : "";
      const copyName: string = name;
      if (name) {
        name = name.toLowerCase();
      } else {
        name = "";
      }
      let company: string = "";
      let formatted_address: string = formattedAddress;
      if (formattedAddress) {
        formattedAddress = formattedAddress.toLowerCase();
      } else {
        formattedAddress = "";
      }
      // if ( (this && (typeof name !== "undefined") && (originalAddress.toLowerCase().indexOf(name) === -1)) && name !== (components.postalCode ? components.postalCode.long_name : '') && name !== (components.country ? components.country.long_name : '')) {
      //   company = copyName;
      // }

      const nameExistInComponents: boolean = this.address_components
        ? this.nameExists(copyName.toLowerCase(), this.address_components)
        : true;
      // nameExistInComponents check if exist the name given by Google maps into the address components
      // to avoid write the name of the address in company field
      if (
        !nameExistInComponents &&
        this.company != company &&
        !this!.formatted_address?.includes(company) &&
        this.originalFormattedAddress?.includes(
          this.formatted_address as string
        )
      ) {
        formatted_address = company + ", " + formatted_address;
        this.company = company;
      }
      this!.formatted_address = formatted_address
        ? formatted_address.trim()
        : "";
    } catch (e) {
      return;
    }
  }

  public getFormattedAddress(details): void {
    if (details) {
      let formattedAddress = "";
      if (details.address1?.trim()) {
        formattedAddress += details.address1;
      }
      if (details.address2?.trim()) {
        formattedAddress += ", " + details.address2;
      }
      if (details.address3?.trim()) {
        formattedAddress += " " + details.address3;
      }
      if (details.city?.trim()) {
        formattedAddress +=
          (formattedAddress.length > 0 ? ", " : "") + details.city;
      }
      // if(details.state){
      //   formattedAddress += ', ' + (details.state.stateprovcode ? details.state.stateprovcode : details.state);
      // }
      if (details.state) {
        if (typeof details.state === "object") {
          formattedAddress += ", " + details.state.stateprovcode;
        } else {
          formattedAddress += ", " + details.state;
        }
      }
      if (details.postalCode) {
        formattedAddress += " " + details.postalCode;
      }
      if (details.country) {
        formattedAddress +=
          ", " +
          (details.country.code ? details.country.code : details.country);
      }
      this.formatted_address = formattedAddress;
    }
  }
}

export default Place;
