
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Modal from "~/components/Modal.vue";
import ConfirmLogin from "~/components/auth/ConfirmLogin.vue";
import ForgotPassword from "~/components/auth/ForgotPassword.vue";
import Login from "~/components/auth/Login.vue";

export default Vue.extend({
  components: { Login, ForgotPassword, Modal, ConfirmLogin },
  props: {
    activeMenu: Number,
  },
  data() {
    return {
      activeForm: 0,
      confirmed: false,
      isOpen: false,
      userEmail: "" as string | unknown,
    };
  },
  computed: {
    // Vuex getters
    ...mapGetters({
      session: "user/session",
      user: "user",
      timeoutLogout: "user/timeoutLogout",
    }),
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logoutUser",
    }),
    ...mapMutations({
      setTimeoutLogout: "user/setTimeoutLogout",
    }),
    onChecked() {
      this.$emit("toggleMenu", 1);
    },
    onSwitchForm(activeForm: number) {
      this.activeForm = activeForm;
    },
    closeMenu() {
      this.$emit("toggleMenu", 0);
    },
    async logout() {
      try {
        await this.logoutUser();
        window.location.reload();
        this.setTimeoutLogout(false);
      } catch (e) {
        console.log(e);
        this.setTimeoutLogout(false);
      }
    },
    confirmationMade() {
      (this as any).confirmed = true;
    },
    onClick() {
      this.$emit("linkClicked");
    },
    hideModal() {
      this.logout();
    },
    modalConfirmationMade() {
      if (this.isOpen) {
        this.isOpen = false;
        this.setTimeoutLogout(false);
      }
    },
  },
  watch: {
    timeoutLogout(newVal) {
      if (this.$auth.user && newVal)
        this.userEmail = this.$auth.user.email ? this.$auth.user.email : null;
      this.isOpen = newVal;
    },
  },
});
