
import Vue from "vue";

export default Vue.extend({
  props: {
    activeMenu: Number,
    isOpen: Boolean,
    MaxSizeClass: String,
    justifyModalClass: String,
  },
  methods: {
    modalClose() {
      this.$emit("closeModal", true);
    },
  },
});
