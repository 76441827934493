export default class Notice {
  public pages!: Array<string>;
  public page!: string;
  public key!: string;
  public name!: string;
  public type!: string;
  public expletive!: string;
  public message!: string;
  public replacements!: {};
  public startTime!: Date;
  public endTime!: Date;
  public dismissable!: boolean;

  public constructor(init?: Partial<Notice>) {
    if (init) {
      if (init instanceof Notice) {
        Object.assign(this, init);
      } else {
        this.pages = [init.page ? init.page : ""];
        this.page = init.page ? init.page : "";
        this.key = init.key ? init.key : "";
        this.name = init.name ? init.name : "";
        this.type = init.type ? init.type : "";
        this.expletive = init.expletive ? init.expletive : "";
        this.message = init.message ? init.message : "";
        this.replacements = {};
        this.startTime = new Date(init.startTime ? init.startTime : "");
        this.endTime = new Date(init.endTime ? init.endTime : "");
        this.dismissable = init.dismissable ? init.dismissable : false;
      }
    } else {
      this.pages = [];
      this.page = "";
      this.key = "";
      this.name = "";
      this.type = "";
      this.expletive = "";
      this.message = "";
      this.replacements = {};
      this.startTime = new Date();
      this.endTime = new Date();
      this.dismissable = false;
    }
  }
}
