import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d5597b5a = () => interopDefault(import('..\\pages\\aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _5c679b36 = () => interopDefault(import('..\\pages\\accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _5407f3b8 = () => interopDefault(import('..\\pages\\advocacy.vue' /* webpackChunkName: "pages/advocacy" */))
const _3a58efb5 = () => interopDefault(import('..\\pages\\affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _3dc59cac = () => interopDefault(import('..\\pages\\ambassadors.vue' /* webpackChunkName: "pages/ambassadors" */))
const _09a057c6 = () => interopDefault(import('..\\pages\\assembly-guide.vue' /* webpackChunkName: "pages/assembly-guide" */))
const _7752d5e6 = () => interopDefault(import('..\\pages\\bicycleshops.vue' /* webpackChunkName: "pages/bicycleshops" */))
const _02cb57c3 = () => interopDefault(import('..\\pages\\branding.vue' /* webpackChunkName: "pages/branding" */))
const _036ca3b2 = () => interopDefault(import('..\\pages\\buckupforbikes.vue' /* webpackChunkName: "pages/buckupforbikes" */))
const _1d89599a = () => interopDefault(import('..\\pages\\changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _55454b02 = () => interopDefault(import('..\\pages\\claims.vue' /* webpackChunkName: "pages/claims" */))
const _1c7d19c3 = () => interopDefault(import('..\\pages\\claimsform.vue' /* webpackChunkName: "pages/claimsform" */))
const _15f2d806 = () => interopDefault(import('..\\pages\\confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _0a8f4833 = () => interopDefault(import('..\\pages\\contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _b780de9e = () => interopDefault(import('..\\pages\\dimensionsandrates.vue' /* webpackChunkName: "pages/dimensionsandrates" */))
const _64019a9a = () => interopDefault(import('..\\pages\\e-bike-shipping.vue' /* webpackChunkName: "pages/e-bike-shipping" */))
const _72329149 = () => interopDefault(import('..\\pages\\event-shipping.vue' /* webpackChunkName: "pages/event-shipping" */))
const _01e18e26 = () => interopDefault(import('..\\pages\\forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _6ee41370 = () => interopDefault(import('..\\pages\\guarantee.vue' /* webpackChunkName: "pages/guarantee" */))
const _317da77c = () => interopDefault(import('..\\pages\\history.vue' /* webpackChunkName: "pages/history" */))
const _08c20547 = () => interopDefault(import('..\\pages\\international_bicycle_shipping.vue' /* webpackChunkName: "pages/international_bicycle_shipping" */))
const _6a64c8c4 = () => interopDefault(import('..\\pages\\jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _9b7ad85e = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _56c187e1 = () => interopDefault(import('..\\pages\\pack.vue' /* webpackChunkName: "pages/pack" */))
const _685eb780 = () => interopDefault(import('..\\pages\\packing-guide.vue' /* webpackChunkName: "pages/packing-guide" */))
const _648a6c32 = () => interopDefault(import('..\\pages\\pre-confirmation.vue' /* webpackChunkName: "pages/pre-confirmation" */))
const _2f5d46a2 = () => interopDefault(import('..\\pages\\privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _2719ba11 = () => interopDefault(import('..\\pages\\profile.vue' /* webpackChunkName: "pages/profile" */))
const _113aee58 = () => interopDefault(import('..\\pages\\QBPtopshop.vue' /* webpackChunkName: "pages/QBPtopshop" */))
const _3c67cff2 = () => interopDefault(import('..\\pages\\refundpolicy.vue' /* webpackChunkName: "pages/refundpolicy" */))
const _1000a76b = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _18bca240 = () => interopDefault(import('..\\pages\\registerthankyou.vue' /* webpackChunkName: "pages/registerthankyou" */))
const _79235321 = () => interopDefault(import('..\\pages\\reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _0b343684 = () => interopDefault(import('..\\pages\\ship.vue' /* webpackChunkName: "pages/ship" */))
const _0f33d6a8 = () => interopDefault(import('..\\pages\\shippingpolicy.vue' /* webpackChunkName: "pages/shippingpolicy" */))
const _f6da5870 = () => interopDefault(import('..\\pages\\staff.vue' /* webpackChunkName: "pages/staff" */))
const _2605ef22 = () => interopDefault(import('..\\pages\\sustainability.vue' /* webpackChunkName: "pages/sustainability" */))
const _0784c21f = () => interopDefault(import('..\\pages\\termsofservice.vue' /* webpackChunkName: "pages/termsofservice" */))
const _bd7c3c6e = () => interopDefault(import('..\\pages\\timeout.vue' /* webpackChunkName: "pages/timeout" */))
const _443cd4b3 = () => interopDefault(import('..\\pages\\track.vue' /* webpackChunkName: "pages/track" */))
const _05806d7f = () => interopDefault(import('..\\pages\\tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _98989414 = () => interopDefault(import('..\\pages\\trustpilot-reviews.vue' /* webpackChunkName: "pages/trustpilot-reviews" */))
const _3b994ffd = () => interopDefault(import('..\\pages\\workwithus.vue' /* webpackChunkName: "pages/workwithus" */))
const _611a7282 = () => interopDefault(import('..\\pages\\youraccount.vue' /* webpackChunkName: "pages/youraccount" */))
const _2cf8f392 = () => interopDefault(import('..\\pages\\youraddresses.vue' /* webpackChunkName: "pages/youraddresses" */))
const _7852714e = () => interopDefault(import('..\\pages\\yourboxes.vue' /* webpackChunkName: "pages/yourboxes" */))
const _ca39e9c0 = () => interopDefault(import('..\\pages\\yourorders.vue' /* webpackChunkName: "pages/yourorders" */))
const _6732d6a8 = () => interopDefault(import('..\\pages\\yourpayments.vue' /* webpackChunkName: "pages/yourpayments" */))
const _ace4995e = () => interopDefault(import('..\\pages\\yoursearches.vue' /* webpackChunkName: "pages/yoursearches" */))
const _9e238508 = () => interopDefault(import('..\\pages\\events\\_event.vue' /* webpackChunkName: "pages/events/_event" */))
const _12ea220e = () => interopDefault(import('..\\pages\\partners\\_event.vue' /* webpackChunkName: "pages/partners/_event" */))
const _4e0df58f = () => interopDefault(import('..\\pages\\event2\\_event\\_id.vue' /* webpackChunkName: "pages/event2/_event/_id" */))
const _2eca1e8c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _ac09833a = () => interopDefault(import('..\\pages\\_event.vue' /* webpackChunkName: "pages/_event" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _d5597b5a,
    name: "aboutus"
  }, {
    path: "/accessibility",
    component: _5c679b36,
    name: "accessibility"
  }, {
    path: "/advocacy",
    component: _5407f3b8,
    name: "advocacy"
  }, {
    path: "/affiliate",
    component: _3a58efb5,
    name: "affiliate"
  }, {
    path: "/ambassadors",
    component: _3dc59cac,
    name: "ambassadors"
  }, {
    path: "/assembly-guide",
    component: _09a057c6,
    name: "assembly-guide"
  }, {
    path: "/bicycleshops",
    component: _7752d5e6,
    name: "bicycleshops"
  }, {
    path: "/branding",
    component: _02cb57c3,
    name: "branding"
  }, {
    path: "/buckupforbikes",
    component: _036ca3b2,
    name: "buckupforbikes"
  }, {
    path: "/changePassword",
    component: _1d89599a,
    name: "changePassword"
  }, {
    path: "/claims",
    component: _55454b02,
    name: "claims"
  }, {
    path: "/claimsform",
    component: _1c7d19c3,
    name: "claimsform"
  }, {
    path: "/confirmation",
    component: _15f2d806,
    name: "confirmation"
  }, {
    path: "/contact-us",
    component: _0a8f4833,
    name: "contact-us"
  }, {
    path: "/dimensionsandrates",
    component: _b780de9e,
    name: "dimensionsandrates"
  }, {
    path: "/e-bike-shipping",
    component: _64019a9a,
    name: "e-bike-shipping"
  }, {
    path: "/event-shipping",
    component: _72329149,
    name: "event-shipping"
  }, {
    path: "/forgotPassword",
    component: _01e18e26,
    name: "forgotPassword"
  }, {
    path: "/guarantee",
    component: _6ee41370,
    name: "guarantee"
  }, {
    path: "/history",
    component: _317da77c,
    name: "history"
  }, {
    path: "/international_bicycle_shipping",
    component: _08c20547,
    name: "international_bicycle_shipping"
  }, {
    path: "/jobs",
    component: _6a64c8c4,
    name: "jobs"
  }, {
    path: "/login",
    component: _9b7ad85e,
    name: "login"
  }, {
    path: "/pack",
    component: _56c187e1,
    name: "pack"
  }, {
    path: "/packing-guide",
    component: _685eb780,
    name: "packing-guide"
  }, {
    path: "/pre-confirmation",
    component: _648a6c32,
    name: "pre-confirmation"
  }, {
    path: "/privacypolicy",
    component: _2f5d46a2,
    name: "privacypolicy"
  }, {
    path: "/profile",
    component: _2719ba11,
    name: "profile"
  }, {
    path: "/QBPtopshop",
    component: _113aee58,
    name: "QBPtopshop"
  }, {
    path: "/refundpolicy",
    component: _3c67cff2,
    name: "refundpolicy"
  }, {
    path: "/register",
    component: _1000a76b,
    name: "register"
  }, {
    path: "/registerthankyou",
    component: _18bca240,
    name: "registerthankyou"
  }, {
    path: "/reset-password",
    component: _79235321,
    name: "reset-password"
  }, {
    path: "/ship",
    component: _0b343684,
    name: "ship"
  }, {
    path: "/shippingpolicy",
    component: _0f33d6a8,
    name: "shippingpolicy"
  }, {
    path: "/staff",
    component: _f6da5870,
    name: "staff"
  }, {
    path: "/sustainability",
    component: _2605ef22,
    name: "sustainability"
  }, {
    path: "/termsofservice",
    component: _0784c21f,
    name: "termsofservice"
  }, {
    path: "/timeout",
    component: _bd7c3c6e,
    name: "timeout"
  }, {
    path: "/track",
    component: _443cd4b3,
    name: "track"
  }, {
    path: "/tracking",
    component: _05806d7f,
    name: "tracking"
  }, {
    path: "/trustpilot-reviews",
    component: _98989414,
    name: "trustpilot-reviews"
  }, {
    path: "/workwithus",
    component: _3b994ffd,
    name: "workwithus"
  }, {
    path: "/youraccount",
    component: _611a7282,
    name: "youraccount"
  }, {
    path: "/youraddresses",
    component: _2cf8f392,
    name: "youraddresses"
  }, {
    path: "/yourboxes",
    component: _7852714e,
    name: "yourboxes"
  }, {
    path: "/yourorders",
    component: _ca39e9c0,
    name: "yourorders"
  }, {
    path: "/yourpayments",
    component: _6732d6a8,
    name: "yourpayments"
  }, {
    path: "/yoursearches",
    component: _ace4995e,
    name: "yoursearches"
  }, {
    path: "/events/:event?",
    component: _9e238508,
    name: "events-event"
  }, {
    path: "/partners/:event?",
    component: _12ea220e,
    name: "partners-event"
  }, {
    path: "/event2/:event?/:id?",
    component: _4e0df58f,
    name: "event2-event-id"
  }, {
    path: "/",
    component: _2eca1e8c,
    name: "index"
  }, {
    path: "/:event",
    component: _ac09833a,
    name: "event"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
