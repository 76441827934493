
import Vue from "vue";

export default Vue.extend({
  props: {
    activeMenu: Number,
  },
  methods: {
    onChecked() {
      this.$emit("toggleMenu", 2);
    },
    onClick() {
      this.$emit("linkClicked");
    },
  },
});
