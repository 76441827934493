import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=c9ec28fe&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=c9ec28fe&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9ec28fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('C:/inetpub/wwwroot/bike-flights/components/Footer.vue').default})
