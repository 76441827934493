import { render, staticRenderFns } from "./ConfirmLogin.vue?vue&type=template&id=1296c586&scoped=true&"
import script from "./ConfirmLogin.vue?vue&type=script&lang=ts&"
export * from "./ConfirmLogin.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmLogin.vue?vue&type=style&index=0&id=1296c586&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1296c586",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notice: require('C:/inetpub/wwwroot/bike-flights/components/Notice.vue').default})
