
import * as tinyCookie from "tiny-cookie";
import Vue from "vue";

export default Vue.extend({
  name: "Cookie",
  props: {
    debug: {
      type: Boolean,
      default: false,
    },

    // slideFromBottom, slideFromTop, fade
    transitionName: {
      type: String,
      default: "slideFromBottom",
    },

    showPostponeButton: {
      type: Boolean,
      default: false,
    },

    forceCookies: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: null,
      supportsLocalStorage: true,
      isOpen: false,
    };
  },
  mounted() {
    this.checkLocalStorageFunctionality();
    this.init();
  },
  methods: {
    init() {
      const visitedType = this.getCookieStatus();

      if (visitedType && visitedType === "accept") {
        this.isOpen = false;
      }

      if (!visitedType) {
        this.isOpen = true;
      }

      this.status = visitedType;
      this.$emit("status", visitedType);
    },
    checkLocalStorageFunctionality() {
      if (this.forceCookies) {
        this.supportsLocalStorage = false;
        return;
      }
      // Check for availability of localStorage
      try {
        const test = "__bikeflight-cookie-accept-decline-check-localStorage";
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
      } catch (e) {
        console.error(
          "Local storage is not supported, falling back to cookie use"
        );
        this.supportsLocalStorage = false;
      }
    },
    setCookieStatus(type) {
      if (this.supportsLocalStorage) {
        if (type === "accept") {
          localStorage.setItem(`bikeflight-cookie-accept`, "accept");
        }
      } else if (type === "accept") {
        tinyCookie.set(`bikeflight-cookie-accept`, "accept");
      }
    },
    getCookieStatus() {
      if (this.supportsLocalStorage) {
        return localStorage.getItem(`bikeflight-cookie-accept`);
      } else {
        return tinyCookie.get(`bikeflight-cookie-accept`);
      }
    },
    accept() {
      if (!this.debug) {
        this.setCookieStatus("accept");
      }
      this.status = "accept";
      this.isOpen = false;
      this.$emit("clicked-accept");
    },
    removeCookie() {
      localStorage.removeItem(`bikeflight-cookie-accept`);
      this.status = null;
      this.$emit("removed-cookie");
    },
  },
});
