function routeOption(route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      return Object.values(m.components).some(
        (component) => component.options && component.options[key] === value
      );
    } else {
      return Object.values(m.components).some((component) =>
        Object.values(component._Ctor).some(
          (ctor) => ctor.options && ctor.options[key] === value
        )
      );
    }
  });
}

export default function ({ $auth }) {
  $auth.$storage.watchState("loggedIn", (loggedIn) => {
    if (!routeOption($auth.ctx.route, "auth", false)) {
      // If its a protected route with middleware: auth, then redirect to login after logout
      if (!loggedIn && routeOption($auth.ctx.route, "middleware", "auth"))
        $auth.redirect("logout");
    }
  });
}
