// with that pluggin can use the store in the assets/utils folder, or use the store outside the Vue components
import { Store } from "vuex";

// Import the Store type from Vuex

let store: Store<any>; // Define the store variable with the correct type

export const initializeStores = (storeInstance: Store<any>): void => {
  store = storeInstance;
};

export const getStore = (): Store<any> => {
  return store;
};
